<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        From the information provided, use <b>cell notation</b> to describe the following galvanic
        cells connected through a salt bridge. Note that you can insert the vertical lines for phase
        boundaries and the salt bridge using the pipe character "|" which shares a key with the
        backslash "\", which can be found above the Enter/Return key on your keyboard.
      </p>

      <p class="mb-2">
        a) In one half-cell, a solution of
        <chemical-latex content="Pt(NO3)2" />
        forms
        <chemical-latex content="Pt" />
        metal, while in the other half-cell,
        <chemical-latex content="Cu" />
        metal goes into a
        <chemical-latex content="Cu(NO3)2" />
        solution with all solute concentrations 1 M.
      </p>

      <p>
        <chemical-notation-input
          v-model="inputs.cellNotation1"
          dense
          :show-note="false"
          class="mb-8"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>

      <p class="mb-2">
        b) The cathode consists of a gold electrode in a
        <stemble-latex content="$0.55\,\text{M }\ce{Au(NO3)3}$" />
        solution and the anode is a magnesium electrode in
        <stemble-latex content="$0.75\,\text{M }\ce{Mg(NO3)2}$" />
        solution.
      </p>

      <p>
        <chemical-notation-input
          v-model="inputs.cellNotation2"
          dense
          :show-note="false"
          class="mb-8"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>

      <p class="mb-2">
        c) One half-cell consists of a silver electrode in a
        <stemble-latex content="$1\,\text{M }\ce{AgNO3}$" />
        solution, and in the other half-cell, a copper electrode in
        <stemble-latex content="$1\,\text{M }\ce{Cu(NO3)2}$" />
        is oxidized.
      </p>

      <p>
        <chemical-notation-input
          v-model="inputs.cellNotation3"
          dense
          :show-note="false"
          :disabled="!allowEditing"
          disable-validation
        />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question424',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        cellNotation1: null,
        cellNotation2: null,
        cellNotation3: null,
      },
    };
  },
};
</script>
